import React from "react"
import Layout from "../components/layout"
import { graphql, useStaticQuery } from "gatsby"
import MlIcon from "../components/mlIcon"
import NetworkIcon from "../images/chart-network.svg"
import BrainIcon from "../images/brain.svg"
import BrowserIcon from "../images/browser.svg"
import FingerprintIcon from "../images/fingerprint.svg"
import ListIcon from "../images/list-ul.svg"
import ChartIcon from "../images/chart-line.svg"

export default () => {
  const {
    file: {
      childMarkdownRemark: { frontmatter },
    },
  } = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "pages/about.md" }) {
        childMarkdownRemark {
          html
          frontmatter {
            title
            meta {
              title
              description
            }
            team {
              name
              member {
                name
                position
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Layout meta={frontmatter.meta}>
      <section>
        <div className="container">
          <div className="row">
            <div className="sub-page-heading">
              <MlIcon />
              <h1>{frontmatter.title}</h1>
              <h2>
                Creative Thinking Institute is a biotechnology company that is
                using MedTech to create a comprehensive health platform that
                maximises an individual’s stress performance levels.
              </h2>
            </div>
          </div>
        </div>
      </section>
      <section className="container">
        <div className="row">
          <p>
            The CEO and founder is{" "}
            <a href="https://www.linkedin.com/in/brendan-o-brien-30408630/">
              Brendan O’Brien
            </a>
            , a Neurosurgeon with over 25 years clinical surgical experience,
            working from St Vincent’s Hospital.
          </p>

          <p>
            Working together in a start-up environment with other medical
            practitioners - GP, Cardiologist, and ENT surgeon to provide cutting
            edge health products to address and assist in human work and home
            functioning, in a day to day setting. Aided by leading organisation
            psychologists, research scientists and software developers.
          </p>
        </div>
      </section>

      <section className="lg-pad">
        <div className="container">
          <div className="row">
            <h3>CTI Products</h3>
            <p>Creative Thinking Institute are working on products that:</p>
            <div className="grid-3-col">
              <div className="product-feature">
                <img
                  src={FingerprintIcon}
                  className=""
                  alt="an icon showing a chart network"
                  aria-hidden="true"
                />
                <p>
                  Use biosensor technology to assess physiological biomarkers of
                  a person’s performance in work and at home.
                </p>
              </div>
              <div className="product-feature">
                <img
                  src={BrainIcon}
                  className=""
                  alt="an icon showing a chart network"
                  aria-hidden="true"
                />
                <p>
                  Combine this with newly designed psychometrics to assess the
                  total person.
                </p>
              </div>
              <div className="product-feature">
                <img
                  src={BrowserIcon}
                  className=""
                  alt="an icon showing a chart network"
                  aria-hidden="true"
                />
                <p>
                  Deliver information privately to the individual through mobile
                  and web-based applications.
                </p>
              </div>
              <div className="product-feature">
                <img
                  src={ListIcon}
                  className=""
                  alt="an icon showing a chart network"
                  aria-hidden="true"
                />
                <p>
                  Recommend a range of actionable items to address areas of
                  excessive stress, overworking, depersonalisation and emotional
                  exhaustion.
                </p>
              </div>
              <div className="product-feature">
                <img
                  src={ChartIcon}
                  className=""
                  alt="an icon showing a chart network"
                  aria-hidden="true"
                />
                <p>
                  Aim to improve an individual’s adaptability and agility in
                  this technological age, we are surrounded by.
                </p>
              </div>
              <div className="product-feature">
                <img
                  src={NetworkIcon}
                  className=""
                  alt="an icon showing a chart network"
                  aria-hidden="true"
                />
                <p>
                  Use cloud-based machine learning and artificial intelligence
                  to create and refine algorithmic de-identified data assessment
                  that refines research understanding in the stress- performance
                  arena across society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {frontmatter.team.map(team => (
        <section>
          <div className="container">
            <div className="row mt-2">
              <h4>{team.name}</h4>
              <div className="grid-3-col">
                {team.member.map(member => (
                  <div className="team-member">
                    <span>{member.position}</span>
                    {member.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>
      ))}
    </Layout>
  )
}
